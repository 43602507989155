import React from 'react'
import { Link } from 'gatsby'
import profileJohn from '../images/profile-john.jpeg'
import profileGordon from '../images/profile-gordon.jpeg'
import profileCarlos from '../images/profile-amado.jpeg'
import profileJohnB from '../images/profile-buckley.jpeg'
import profileLange from '../images/profile-lange.png'
import profileCynthiaLange from '../images/profile-cynthialange.jpeg'

import Layout from '../components/layout'

const TrusteesPage = () => (
  <Layout>
    <h2 className='major'>Board of Trustees</h2>
    <h3 id='Duval'>Gordon Duval, Trustee</h3>
    <h4 id='Duval'>In Memoriam</h4>
    <img width='20%' src={profileGordon} alt='' />
    <p>
      Gordon Duval, J.D., a member of the Board of Directors, passed at the end of January, 2021. He was an attorney
      with 30 years of experience in the public and private sector with an emphasis in international business, natural
      resource, government, real estate, and construction. He had experience representing clients in international
      business ventures in China, Thailand, and Venezuela, and his practice included 20 years’ experience as the legal
      advisor to over 30 state, city, county, university, and school entities in Alaska, Oregon, and Utah. Yet he
      preferred to be remembered as a man of faith, of kindness, and generosity. He was a founding member of the
      foundation and will be sorely missed. The foundation expresses its gratitude for his efforts.
    </p>
    <h3 id='Harris'>John Harris, Executive Director</h3>
    <img width='20%' src={profileJohn} alt='' />
    <p>
      John has spent much of his life as an educator, international executive and senior diplomat. He organized
      educational programs in Peru, Bolivia, and Ecuador, and presided over a religious mission in Argentina in his
      early thirties. He also lived and served in different capacities in Mexico, Chile, Uruguay, Argentina, Brazil and
      Israel. An Engineer and MBA by profession, he is fluent in three languages. He is the father of five children and
      grandfather of twelve grandchildren.
    </p>
    <h3 id='Buckley'>John Buckley, Trustee</h3>
    <img width='20%' src={profileJohnB} alt='' />
    <p>
      John W. Buckley (J.D.) is a man of principle and excellent reputation. He received his JD from Brigham Young
      University after graduating Magna Cum Laude in Communications, as well as graduating as a Hinckley and National
      Scholar from the same university. He has been a member of the Utah State Bar since 1983 and has successfully
      managed his own law firm since then.
    </p>
    <h3 id='Amado'>Amado, Carlos H. (Seventy Emeritus)</h3>
    <img width='20%' src={profileCarlos} alt='' />
    <p>
      Amado, Carlos H. (Seventy Emeritus) was sustained a member of the Second Quorum of the Seventy of The Church of
      Jesus Christ of Latter-day Saints in 1989, and of the First Quorum of the Seventy in 1992. He became Emeritus in
      2015. For approximately 25 years of service, he became very familiar with the humanitarian needs of Latin America,
      having served as president of the Chile Area (Chile,) the South America West Area (Peru and Bolivia,) the South
      America South Area (Argentina, Uruguay and Paraguay,) the Central America Area (Guatemala, El Salvador, Honduras,
      Nicaragua, Costa Rica, Panama and Belize) and Mexico Area (Mexico.) In those positions he cared for the people of
      those countries and played a significant role in key humanitarian decisions.
    </p>
    <h3 id="CynthiaLange">Cynthia Lange, J.D.</h3>
    <img width="20%" src={profileCynthiaLange} alt="" />
    <p>
      Cynthia has an impressive background as she leads the Fragomen’s High Tech law practice centered on immigration
      issues in the heart of Silicon Valley. As the managing partner of the firm’s Northern California practice, she
      directs the strategic representation of large Fortune 500 companies to small start-up companies in the firm’s
      “e-group.” She has worked to set up and manage worldwide immigration compliance programs. She is an Adjunct
      Professor at Southwestern University School of Law and has written numerous articles on business immigration law
      matters. She graduated from Southwestern University School of Law | J.D., 1985 and Brigham Young University |
      B.A., 1981, Phi Kappa Phi Honor Society. She was listed in Best Lawyers in America, from 2006 to 2019, and Who's
      Who Legal: Corporate Immigration, 2006 to 2019. Fluent in English and Spanish.
    </p>
    <h3 id="Lange">Dennis Lange, M.D.</h3>
    <img width="20%" src={profileLange} alt="" />
    <p>
      Dr. Lange graduated from the University of California, Los Angeles, with a Bachelors of Science degree in Biology,
      in 1984, followed by a Doctor of Medicine degree in 1988. <br />
      Dr. Lange finished an Internal Medicine residency in 1991, at King-Drew Medical Center in Los Angeles, where he
      served one year as Chief Resident. He completed a fellowship in Nephrology at the University of Southern
      California in 1993. <br />
      Dr. Lange practiced in the Los Angeles area where he served as the medical director of an in-patient acute
      dialysis service, and as medical director for several outpatient chronic dialysis clinics. <br />
      Dr. Lange has served in leadership, including as the Chair for the Department of Medicine, at Beverly Hospital, in
      Montebello, CA where he was on staff. <br />
      Dr. Lange retired from practice in 2015, and is currently working on a Masters of Public Health at the University
      of California, Berkeley. <br />
      Dr. Lange is fluent in both English and Spanish.
    </p>
    <Link to='/'>Go back to the homepage</Link>
  </Layout>
)

export default TrusteesPage
